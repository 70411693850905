<template>
  <el-row :gutter="12">
    <el-col :span="12" :xs="24" :md="12" :sm="12">
      <el-card class="box-card">
        <div class="clearfix" slot="header">
          <el-link class="title">基本信息（内）</el-link>
        </div>
        <el-form class="newForm" label-suffix="：">
          <el-form-item label="项目">
            {{ infoData.name || '暂无' }}
          </el-form-item>
          <el-form-item label="建单人">
            {{ infoData?.create_user?.nickname || '暂无' }}
          </el-form-item>
          <el-form-item label="意向达人" v-if="infoData.type===1">
            <div v-if="infoData?.intentions?.length > 0">
              <span :key="i.id" v-for="i in infoData.intentions">{{ i?.nickname || '' }}</span></div>
            <div v-else>暂无意向达人</div>
          </el-form-item>
          <el-form-item label="品牌">
            {{ infoData.brand || '暂无' }}
            <span style="color: #E6A23C">（是否寄回：{{ infoData.is_send_back == 1 ? '是' : '否' }}）</span>
          </el-form-item>
          <el-form-item label="产品">
            {{ infoData.product || '暂无' }}
          </el-form-item>
          <el-form-item label="所属集团">
            {{ infoData?.group?.display_name || '暂无' }}
          </el-form-item>
          <el-form-item label="产品资料">
            <span v-if="this.intro!==''" v-html="this.intro"></span>
            <span v-else>暂未提供资料</span>
            <div>
              <span v-for="item in infoData.attachments" :key="item.id">
                <i class="el-icon-download" style="color: #ff3176"></i>
              <a :href="item.full_path" target="_blank">{{ item.original || '附件下载' }}</a>
              </span>
            </div>
          </el-form-item>
          <el-form-item label="平台">
            <span :key="i.value" v-for="i in infoPlatforms"> {{ i.name }} </span>
            <span style="color: #E6A23C;padding-left: 20px">{{ infoData.coop ? infoData.coop.display_name : '' }}</span>
          </el-form-item>
          <el-form-item label="是否平台下单">
            {{ infoData.is_platform_pay === 1 ? '是' : '否' || '暂无' }}
          </el-form-item>

          <el-form-item v-if="infoData.type===2" label="直播机制">
            {{ infoData?.live_mec }}
          </el-form-item>
          <el-form-item v-if="infoData.type===2" label="是否保量">
            {{ Number(infoData?.is_quantity) === 1 ? '是' : '否' || '' }}
            {{ infoData?.qty_policy }}
          </el-form-item>
          <el-form-item v-if="infoData.type===2" label="有无特别激励政策">
            {{ Number(infoData?.is_policy) === 1 ? '有' : '无' || '' }} /
            {{ infoData.cart_mechanism || '暂无政策' }}
          </el-form-item>
          <el-form-item v-if="infoData.type===1" label="挂车佣金">
            <span>   {{ infoData.cart_rate }}%     </span>
          </el-form-item>
          <el-form-item v-if="infoData.type===1" label="挂车机制">
            {{ infoData.cart_mechanism }}
          </el-form-item>
          <el-form-item label="授权平台">
            <span v-for="(item,index) in infoData?.auth_platform_alias" :key="item+index">   {{ item }},   </span>
          </el-form-item>
          <el-form-item label="期望档期">
            {{ infoData.expect_start }}至{{ infoData.except_end }}
          </el-form-item>
          <el-form-item label="直播明细">
            {{ this.live_detail }}
          </el-form-item>
          <el-form-item label="其他要求">
            {{ this.other_desc }}
          </el-form-item>
          <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>
        </el-form>
        <div class="bird_box" type="info" v-clipboard:copy="copyData"
             v-clipboard:error="onError" v-clipboard:success="onCopyInfo">
          <el-button type="primary" size="big" style="float: right;margin-bottom: 10px">复制</el-button>
        </div>
      </el-card>
    </el-col>
    <el-col :span="12" :xs="24" :md="12" :sm="12">
      <el-card class="box-card">
        <div class="clearfix" slot="header">
          <el-link class="title">基本信息（外）
          </el-link>
        </div>
        <el-form class="newForm" label-suffix="：" ref="form">
          <el-form-item label="贵司名称">
            {{ infoData.brand || '' }}
          </el-form-item>
          <el-form-item
              label="意向达人"
              v-show="infoData?.intentions?.length>0">
                       <span :key="i.id" v-for="i in infoData.intentions">
                           {{ i.nickname }}
                       </span>
          </el-form-item>
          <el-form-item label="平台">
            <span :key="i.value" v-for="i in infoPlatforms">{{ i.name }}, </span>
            <span style="color: #E6A23C;padding-left: 20px">{{ infoData.coop ? infoData.coop.display_name : '' }}</span>
          </el-form-item>
          <el-form-item
              label="产品资料"
              v-show="intro!==''">
            <span v-html="intro"></span>
          </el-form-item>
          <el-form-item
              label="期望档期"
              v-show="infoData.expect_start!==''">
            {{ infoData.expect_start }}至{{ infoData.except_end }}
          </el-form-item>
          <el-form-item
              label="直播明细"
              v-show="live_detail!=''">
            {{ live_detail }}
          </el-form-item>
          <el-form-item
              label="其他要求"
              v-show="other_desc!=''">
            {{ this.other_desc }}
          </el-form-item>
          <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>
          <div v-clipboard:copy="copyInfo"
               :error="onError" :success="onCopyInfo">
            <!--            <BirdButton :bird="false" :text="'复制'"></BirdButton>-->
            <el-button type="primary" size="big" style="float: right;margin-bottom: 10px">复制</el-button>
          </div>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import BirdButton from '@/components/Style/birdButton.vue'

export default {
  name: 'infoCard',
  data() {
    return {
      infoData: {},
      //已有平台
      infoPlatforms: [],
      projectId: '',
      copyInfo: '请重试',
      copyData: '请重试',
      intro: '',
      other_desc: '',
      live_detail: ''
    }
  },
  components: {
    BirdButton
  },
  methods: {
    onCopyInfo() {

      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    onError() {
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
    async getInfo() {
      let { info } = await this.$api.infoInquiryProject({ id: this.projectId })
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      let platforms = list
      platforms.forEach((i) => {
        if (i.name === 'package') {
          i.children.forEach((v) => {
            platforms.push(v)
          })
        }
      })
      let regexp = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|\\&|-)+)/g
      this.intro = info['intro'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      this.other_desc = info['other_desc'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      this.live_detail = info['live_detail'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      //InquiryKolList
      this.$nextTick(() => {
        // this.infoData = info
        let mapNewArray = []
        for (let i in platforms) {
          info.platforms.forEach((item) => {
            if (item !== 'package' && item === platforms[i].name) {
              mapNewArray.push({ name: platforms[i].display_name, value: item })
            }
          })
        }
        this.infoPlatforms = mapNewArray
        this.infoData = info
        this.getCopyData(info)
        this.$emit('infoPlatform', this.infoPlatforms)
        this.$emit('update:infoStatus', { status: info.status, is_apply: info.is_apply, note: info.other_desc })
      })

    },
    getCopyData(info) {
      let nickname = []
      if (info.intentions) {
        nickname = info.intentions.map((i) => {
          return i.nickname
        })
      }
      let Platforms = []
      if (this.infoPlatforms) {
        Platforms = this.infoPlatforms.map((i) => {
          return i.name
        })
      }
      let data, html = ''
      //内部copydata
      if (this.infoData.type === 1) {
        data = `项目：${info.name}\n建单人：${info.create_user?.nickname}\n意向达人：${String(nickname) || '无'}\n平台：${String(Platforms) || '暂无'}\n合作形式：${info.coop ? info.coop.display_name : '暂无'}\n品牌：${info.brand || '暂无'}\n产品：${info.product || '暂无'},(${info.is_send_back == 1 ? '需寄回' : '无需寄回'})\n产品资料：${info.intro || '暂无'}\n挂车佣金：${Number(info.cart_rate) === 0 ? '0' : info.cart_rate + '%' || ''}\n挂车机制：${info.cart_mechanism || '暂无'}\n授权平台：${info?.auth_platform_alias ?? '暂无'}\n是否平台下单：${info?.is_platform_pay === 1 ? '是' : '否' ?? '暂无'}\n期望档期：${info.expect_start}至${info.except_end}\n直播明细：${info.live_detail || '暂无'}\n其他要求：${info.other_desc || '暂无'}\n说明：报名时不备注默认都接受`
      } else {
        data = `项目：${info.name}\n平台：${String(Platforms) || '暂无'}\n合作形式：${info.coop ? info.coop.display_name : '暂无'}\n品牌：${info.brand || '暂无'}\n产品：${info.product || '暂无'}\n产品资料：${info.intro || '暂无'}\n直播机制：${info.live_mec}\n是否保量：${Number(info.is_quantity) === 1 ? `是/政策:${info.qty_policy}` : '否'}  \n有无特别激励政策：${Number(info.is_policy) === 1 ? '有' + `,${info.cart_mechanism}` : '无'}\n授权平台：${info?.auth_platform_alias ?? '暂无'}\n是否平台下单：${info?.is_platform_pay === 1 ? '是' : '否' ?? '暂无'}\n期望档期：${info.expect_start}至${info.except_end}\n直播明细：${info.live_detail || '暂无'}\n其他要求：${info.other_desc || '暂无'}\n说明：报名时不备注默认都接受`
      }
      html = `贵司名称：${info.name}\n意向达人：${String(nickname) || '无'}\n平台：${String(Platforms) || '暂无'}\n合作形式：${info.coop ? info.coop.display_name : '暂无'}\n品牌：${info.brand || '暂无'}\n产品：${info.product || '暂无'} \n产品资料：${info.intro || '暂无'}\n是否保量：${Number(info.is_quantity) === 1 ? '是' : '否'}\n期望档期：${info.expect_start}至${info.except_end}\n直播明细：${info.live_detail || '暂无'}\n其他要求：${info.other_desc || '暂无'}\n说明：报名时不备注默认都接受`
      this.copyData = data
      this.copyInfo = html
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    this.getInfo()
  }
}
</script>

